<div class="container pt-3 pb-3">
  <div class="row">
    <div class="col">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a
          class="navbar-brand"
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
        >
          <img
            id="planitnerd-logo"
            src="../../../assets/images/logo.png"
            alt="Planitnerd"
          />
        </a>
        <a
        class="navbar-brand"
        *ngIf="!hide"
        href="https://apps.apple.com/us/app/planitnerd/id1514923055" target="_blank"
        
      >
        <img style="width: 150px; height: 40px;"
          id="planitnerd-logo"
          src="../../../../assets/images/ios (1).png"
          alt="Planitnerd"
        />
      </a>
      <a
      class="navbar-brand"
      *ngIf="!hide"
      href="https://play.google.com/store/apps/details?id=com.angel.planitnerd" target="_blank"
   
    >
      <img
        id="planitnerd-logo"
        style="width: 150px; height: 40px;"
        src="../../../../assets/images/gplay.png"
        alt="Planitnerd"
      />
    </a>
        <button
          class="hamburger hamburger--squeeze navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarToggler"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarToggler"
          [ngbCollapse]="isCollapsed"
        >
          <ul class="navbar-nav mr-auto"></ul>
          <div class="main-menu-part">
            <ul>
              <li>
                <a
                  id="active"
                  [routerLink]="['/home']"
                  routerLinkActive="router-link-active"
                  (click)="isCollapsed = !isCollapsed;"
                >
                  Home
                </a>
              </li>
            
              <li>
                <a    id="active"
                [routerLink]="['/resources']"
                routerLinkActive="router-link-active"
                (click)="isCollapsed = !isCollapsed;">
                  Resources
                </a>
              </li>
              <li>
                <a
                  id="active"
                  [routerLink]="['/contact-us']"
                  routerLinkActive="router-link-active"
                  (click)="isCollapsed = !isCollapsed;"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
