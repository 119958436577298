import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { QueryService } from './../../../services/query.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  faCopyright = faCopyright;
  getEarlyAccessForm: FormGroup;
  isSubmitted = false;
  getEarlyAccessError: any;
  getEarlyAccessSuccess = false;
  getEarlyAccessFormProgress = false;
  date = new Date();

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.getEarlyAccessForm.valid && event.key === 'Enter') {
      this.onSubmit();
    }
  }

  constructor(
    private queryService: QueryService,
    private formBuilder: FormBuilder
  ) {
    this.getEarlyAccessForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
          ),
        ]),
      ],
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.getEarlyAccessForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;

    if (this.getEarlyAccessForm.invalid) {
      return;
    }

    this.getEarlyAccessFormProgress = true;

    try {
      const data: any = this.getEarlyAccessForm.value;

      this.queryService.getEarlyAccess(data).subscribe(
        (res) => {
          this.getEarlyAccessForm.reset();
          this.isSubmitted = false;
          this.getEarlyAccessSuccess = true;
          this.getEarlyAccessFormProgress = false;
          setTimeout(() => {
            this.getEarlyAccessSuccess = false;
          }, 3000);
        },
        (error) => {
          console.error(error);
          this.getEarlyAccessError = error.error;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
