import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  constructor(private httpClient: HttpClient) {}

  getEarlyAccess(data): Observable<any> {
    return this.postApiResponse(
      `${environment.API_URL}` + '/contact-us/get-early-access',
      data
    );
  }

  contactUs(data): Observable<any> {
    return this.postApiResponse(
      `${environment.API_URL}` + '/contact-us/contact-us',
      data
    );
  }
  getLimitedResources():Observable<any> {
    return this.getApiResponse(
      `${environment.API_URL}` + '/resources/limited-resources',
      
    );
  }

  getLimitedPartnerOffers():Observable<any> {
    return this.getApiResponse(
      `${environment.API_URL}` + '/resources/limited-partner-offers',
      
    );
  }

  getLimitedItinerary():Observable<any> {
    return this.getApiResponse(
      `${environment.API_URL}` + '/trip/post-trip-itinerary',
      
    );
  }

  getResourceDetail(resourceId):Observable<any> {
    return this.getApiResponse(
      `${environment.API_URL}` + '/resources/get-resource-website/'+resourceId,
      
    );
  }
  getItineraryDetail(itineraryId):Observable<any> {
    return this.getApiResponse(
      `${environment.API_URL}` + '/trip/get-itinerary-website/'+itineraryId,
      
    );
  }
  // Common get
  getApiResponse(url: string, data?: object): Observable<any> {
    return this.httpClient.get(url).pipe(
      map((response) => response),
      retry(3),
      catchError((err) => {
        if (err.status === 401) {
          return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );
  }
  updateUserPassword(password, token): Observable<any> {
    return this.postApiResponse(
      `${environment.API_URL}` + '/user/reset-password/' + token,
      { password }
    );
  }
  // Common Post
  postApiResponse(url: string, data: object): Observable<any> {
    return this.httpClient.post(url, data).pipe(
      map((response) => response),
      retry(3),
      catchError((err) => {
        if (err.status === 401) {
          return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );
  }
}
