<div class="footer jumbotron mb-0">
  <div class="container text-center">
    <form
      [formGroup]="getEarlyAccessForm"
      (ngSubmit)="onSubmit()"
      class="needs-validation"
      novalidate
      #form="ngForm"
    >
      <div class="row justify-content-center mb-4">
        <div
          class="col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12 text-center email-box"
        >
          <div class="form-group mb-0">
            <div class="input-group input-group-lg">
              <input
                email
                class="form-control"
                placeholder="Enter your email"
                formControlName="email"
                required
              />
              <div
                class="invalid-feedback d-block text-left"
                *ngIf="!f.email.valid && (f.email.touched || f.email.dirty || isSubmitted) && f.email.errors"
              >
                <span *ngIf="f.email.errors.required">Email is required</span>
                <span *ngIf="f.email.errors.email"
                  >Email must be a valid email address</span
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="getEarlyAccessSuccess"
            class="alert alert-success mt-3"
            role="alert"
          >
            Thank you for sharing your details. We will get in touch with you.
          </div>
          <div
            *ngIf="getEarlyAccessError"
            class="alert alert-error mt-3"
            role="alert"
          >
            {{getEarlyAccessError}}
          </div>
        </div>
        <div
          class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 text-canter get-early-access-btn"
        >
          <ng-container *ngIf="getEarlyAccessFormProgress; else elseTemplate">
            <button
              type="button"
              disabled
              class="btn btn-primary btn-lg get-early-access float-left"
            >
              Please wait...
            </button>
          </ng-container>
          <ng-template #elseTemplate>
            <button
              type="submit"
              class="btn btn-primary btn-lg get-early-access float-left"
            >
            Get Updates
            </button>
          </ng-template>
        </div>
      </div>
    </form>
    <ul>
      <li>
        <a [routerLink]="['/contact-us']" routerLinkActive="router-link-active"
          >CONTACT</a
        >
      </li>
      <li>
        <a
          href="https://planitnerd.s3.amazonaws.com/Planitnerd+Terms+of+Use.pdf"
          target="_blank"
          >TERMS</a
        >
      </li>
      <li>
        <a
          href="https://planitnerd.s3.amazonaws.com/Planitnerd+Privacy+Policy.pdf"
          target="_blank"
          >PRIVACY</a
        >
      </li>
    </ul>
    <ul>
      <li>
        <a href="https://www.facebook.com/planitnerd" target="_blank">
          <img
            src="../../../assets/images/facebook.png"
            alt="https://www.facebook.com/planitnerd"
          />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/planitnerd" target="_blank">
          <img
            src="../../../assets/images/instagram.png"
            alt="https://www.instagram.com/planitnerd"
          />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/planitnerd" target="_blank">
          <img
            src="../../../assets/images/twitter.png"
            alt="https://twitter.com/planitnerd"
          />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/planitnerd" target="_blank">
          <img
            src="../../../assets/images/linkedIn.png"
            alt="https://www.linkedin.com/company/planitnerd"
          />
        </a>
      </li>
    </ul>

      <ul>
        <li>
          <a  href="https://apps.apple.com/us/app/planitnerd/id1514923055" target="_blank">
          <img class="link_img" src="../../../../assets/images/ios (1).png" target="_blank">
        </a>
        </li>
        <li>
          <a href="https://play.google.com/store/apps/details?id=com.angel.planitnerd" target="_blank">
          <img class="link_img" src="../../../../assets/images/gplay.png" target="_blank"></a>
        </li>
      </ul>
    
 
    <div class="row align-items-center justify-content-center">
      <div class="col align-self-center">
        <p class="text-muted">
          <span id="copyright">&#169;</span> Copyright {{date| date: 'yyyy'}} |
          Planitnerd, Inc. <br />
          ALL RIGHTS RESERVED.
        </p>
      </div>
    </div>
  </div>
</div>
