import { Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() target = [];
  @Input() hide
  @Output() scrollToSection = new EventEmitter();
  isCollapsed = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log(this.hide);
    
  }

  onHome() {
    this.router.navigate(['/']);
  }

  onSection(el: HTMLElement) {
    this.onHome();
    this.scrollToSection.emit(el);
  }
}
